import { PAGE_PATH_HOME } from '@bluheadless/ui-logic/src/constants'
import { useStore } from '@bluheadless/ui-logic/src/providers/store'
import { number, object, oneOfType, string } from 'prop-types'
import { useIntl } from 'react-intl'
import Link from '../../molecules/link'
import { useCmsBlock } from '@bluheadless/ui-logic/src/hooks/cms/useCmsBlock'
import Block from '@bluheadless/ui/src/particles/cms-block/block'
import { BackToHome, Divider, Message, Root, Title, Wrapper, ContentWrapper } from './error-template.styled'

const ErrorTemplate = ({ bgImage, bgImageMobile, message, title, buttonProps }) => {
	const { formatMessage } = useIntl()
	const store = useStore()

	const { content } = useCmsBlock({ identifier: '404_page_content' })

	// if store.code is undefined, forceInternal needs to be false
	// or link will redirect to home/en causing an error and geo-ip will not work.
	const forceInternal = !store?.code ? false : undefined

	const titleValue = title ? (typeof title === 'object' ? title?.statusCode : title ? title : '') : ''

	return (
		<Root bgImage={bgImage} bgImageMobile={bgImageMobile}>
			{content?.length > 0 ? (
				<ContentWrapper>
					<Block content={content} />
				</ContentWrapper>
			) : (
				<Wrapper alignItems="center" direction="row" divider={<Divider />} spacing={2.75}>
					{titleValue ? <Title>{titleValue}</Title> : null}
					<Message>{message}</Message>
				</Wrapper>
			)}
			<BackToHome color="primary" component={Link} href={PAGE_PATH_HOME} forceInternal={forceInternal} {...buttonProps}>
				{/* If we are in a server-side error, translations aren't available, so we pass a default message. */}
				{formatMessage({ id: 'back_to_home', defaultMessage: 'Back to home' })}
			</BackToHome>
		</Root>
	)
}

ErrorTemplate.propTypes = {
	/**
	 * Background image
	 */
	bgImage: string,
	/**
	 * Background image mobile
	 */
	bgImageMobile: string,
	/**
	 * Message
	 */
	message: string,
	/**
	 * Title
	 */
	title: oneOfType([object, number]),
}

export default ErrorTemplate
