import { mediaUrl } from '@bluheadless/ui-logic/src/assets'
import { Stack as MuiStack } from '@mui/material'
import { styled } from '@mui/system'
import Typography from '../../atoms/typography'
import Button from '../../molecules/button'

const shouldForwardProp = (prop) => !['bgImage', 'bgImageMobile'].includes(prop)

const Wrapper = styled(MuiStack, { shouldForwardProp })`
	background-size: cover;
	display: flex;
	align-items: center;
	justify-content: center;
	& > :not(style) + :not(style) {
		margin-left: 8px;
	}
`

const Title = styled(Typography)`
	font-size: ${({ theme }) => theme.typography.pxToRem(24)};
	font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`

const Divider = styled('span')`
	background-color: rgba(0, 0, 0, 0.3);
	height: 1px;
	width: 10px;
`

const Root = styled('div')`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-position: center;
	${({ bgImageMobile }) => (bgImageMobile ? `background-image: url(${mediaUrl(bgImageMobile)});` : '')}
	${({ bgImage, theme }) =>
		bgImage
			? `
			${theme.breakpoints.up('md')} {
				background-image: url(${mediaUrl(bgImage)});
			}
		`
			: ''}
`

const BackToHome = styled(Button)`
	margin-top: var(--spacing-5);
`

const Message = styled('p')``

const ContentWrapper = styled('div')`
	margin-top: var(--spacing-3);
	display: inline-block;
	max-width: 100%;
`

export { Divider, Root, Title, BackToHome, Wrapper, Message, ContentWrapper }
